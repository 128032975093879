// 仅支持 chrome 滚动条样式

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ccc;
}

.base-style {
  background-color: #fff;
  margin-bottom: 1rem;
  padding: 1.5rem;
  border-radius: 5px;
  box-shadow: 0px 2px 13px 0px rgba(228, 228, 228, 0.6);
}
.search-result-list{
  margin-top: 10px;
}

.text-primary{
  color: red;
}
.coverimg{
  width: 100%;
  max-width: 120px
}
.adminquill{
  height: 350px;
}